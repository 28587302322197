import React from 'react'
import Banner from './Banner'
import Description from './Description'
import ConnectedBlock from '../../Common/Explore'
import Layout from '../../layout'
import SEO from '../../seo'
import { useSelector } from 'react-redux'

const CareerDetails = ({ pageContext }) => {
  const language = useSelector(state => state.global.language)
  return (
    <Layout>
      <SEO
        title={`${pageContext.positionTitle[language]} - Careers `}
        description={pageContext.description[language]}
      />
      <Banner data={pageContext} />
      <Description role={pageContext} form={pageContext.form} />
      <ConnectedBlock connectedBlock={pageContext.connectedBlock} />
    </Layout>
  )
}

export default CareerDetails
