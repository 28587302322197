import React from 'react'
import { useSelector } from 'react-redux'

const Banner = ({ data }) => {
  const language = useSelector(state => state.global.language)

  return (
    <div className="banner about_banner career_detail_banner">

      <div className="container">
        <div className="banner_txt">
          <div className="w-100">
            <h1 className="text-center">
              {data.positionTitle[language]}
            </h1>
            <p className="text-center w-75 w-sm-100 m-auto">
              {data.description[language]}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Banner
