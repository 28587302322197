import React, { useState } from "react"
import { Modal } from "react-bootstrap"
import { Link } from "gatsby"
import CustomModal from "./Modal"
import { useSelector } from "react-redux"
import { ExploreData } from "../../queries/explore"
const Explore = () => {
  const { language } = useSelector(state => state.global)
  const { connectedBlock } = ExploreData()

  const [modal, setModal] = useState(false)
  return (
    <section className="connect_block">
      <div className="container">
        <h2 className="split-text">{connectedBlock.title[language]}</h2>
        <div className="connect_grey">
          <div className="connect_box">
            <div className="containerr">
              {connectedBlock.blocks &&
                connectedBlock.blocks.map((block, index) => (
                  <div
                    className={`${index === 0 ? "conct_boxwyt" : "conct_boxred"
                      }`}
                  >
                    <h3>{block.title[language]}</h3>
                    <p className="split-text">{block.text[language]}</p>
                    {block.link === null ? (
                      <a
                        onClick={() => setModal(true)}
                        className="indbtn connect_btn"
                      >
                        {block.buttontext[language]}
                        <i className="arrow" />
                      </a>
                    ) : (
                      <Link to={`${block.link}`} className="indbtn connect_btn">
                        {block.buttontext[language]}
                        <i className="arrow" />
                      </Link>
                    )}
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      <Modal show={modal} centered onHide={() => setModal(false)}>
        <CustomModal
          closeModal={() => {
            setModal(false)
          }}
        />
      </Modal>
    </section>
  )
}

export default Explore
