import { graphql, useStaticQuery } from "gatsby"

export const ExploreData = () => {
  const {
    wdwordpress: {
      page: { aboutpage_contents },
    },
  } = useStaticQuery(graphql`
    {
      wdwordpress {
        page(id: "cGFnZTo3Ng==") {
          aboutpage_contents {
            connectedBlock {
              blocks {
                link
                buttontext {
                  en
                  sv
                }
                text {
                  en
                  sv
                }
                title {
                  en
                  sv
                }
              }
              title {
                en
                sv
              }
            }
          }
        }
      }
    }
  `)
  return aboutpage_contents
}
